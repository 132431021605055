export const QUERY = `
  mutation Login($email: ID!, $password: ID!) {
    login(email: $email, password: $password) {
      user {
        id
        email
        userProfile {
          fullName
          avatar
          gender
          about
          isFirstLogin
          timezone(format: ENGLISH)
        }
        capabilities {
          maxSessions
          canManageSessions
          canApproveRequests
          canCheckStyleSubmissionStatus
        }
      }
      token
      expiry
    }
  }
`
