import React from 'react'
import { useMutation } from '@style-space/components/src/lib/graphql/useMutation'

import { UNEXPECTED_ERROR } from '../../../utils/errors'
import { formatDayISO } from '../../../lib/time'

export const MUTATION = `
  mutation SignUp(
    $fullName: String!
    $password: String!
    $email: ID!
    $gender: Gender!
    $dateOfBirth: Date!
    $city: String!
    $country: Country!
    $timezone: Timezone!
  ) {
    token: register(
      fullName: $fullName
      email: $email
      password: $password
      dateOfBirth: $dateOfBirth
      gender: $gender
      location: { city: $city, country: $country }
      timezone: $timezone
    )
  }
`

const EMAIL_IN_USE_ERROR = 'email: Already registered'

export const useSignUp = (onCalled: () => void) => {
  const email = React.useRef('xxx@yyy.ii')
  const { mutate, loading, error } = useMutation<
    { token: string },
    { fullName: string; password: string; dateOfBirth: string; email: string }
  >(MUTATION)

  const signup = React.useCallback(
    async ({
      dateOfBirth,
      ...variables
    }: {
      fullName: string
      password: string
      email: string
      gender: string
      dateOfBirth: Date
      city: string
      country: string
      timezone: string
    }) => {
      email.current = variables.email
      const data = await mutate({
        variables: {
          ...variables,
          dateOfBirth: formatDayISO(dateOfBirth as any),
        },
      })
      onCalled()

      return data?.token || null
    },
    [onCalled],
  )

  const errorMessage =
    error === EMAIL_IN_USE_ERROR
      ? `The e-mail ${email.current} is already in use.`
      : UNEXPECTED_ERROR

  return {
    signup,
    loading,
    error: error && errorMessage,
  }
}
