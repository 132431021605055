import React from 'react'
import { Form, Formik } from 'formik'
import { object } from '@style-space/components/src/Form/schema'
import styled, { css } from 'styled-components'
import { FormikInput } from '@style-space/components/src/Form/Input/FormikInput'
import { Button } from '@style-space/components/src/Button'

import { GENDER } from '../../utils/constants'
import { Select } from '../form/Select'
import { pxToRem } from '../../utils/utils'
import { Title } from '../common/Title'
import { CountriesSelect } from '../form/select/CountriesSelect'
import { TimezonesSelect } from '../form/select/TimezonesSelect'
import { BirthPicker } from './BirthPicker'
import {
  CITY_SCHEMA,
  COUNTRY_SCHEMA,
  DATE_OF_BIRTH_SCHEMA,
  FULL_NAME_SCHEMA,
  GENDER_SCHEMA,
  TIMEZONE_SCHEMA,
} from '../../utils/schemas'
import { getBrowserTimezone } from '../../lib/time'

const SForm = styled(Form)(
  ({ theme }) => css`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      max-width: none;
    }
  `,
)

const SBirthPicker = styled(BirthPicker)`
  margin-bottom: ${pxToRem(4)};
`

const STitle = styled(Title)(
  ({ theme }) => css`
    font-weight: ${theme.font.weight.regular};
    margin-bottom: ${pxToRem(40)};
    font-size: ${pxToRem(32)};
    line-height: 1.125;
  `,
)

const SButton = styled(Button)`
  width: 100%;
`

const SFormikInput = styled(FormikInput)`
  margin-bottom: ${pxToRem(20)};
`

const SCountriesSelect = styled(CountriesSelect)(
  ({ theme }) => css`
    margin-bottom: ${pxToRem(20)};
    label {
      font-weight: ${theme.font.weight.medium};
    }
  `,
)

const STimezonesSelect = styled(TimezonesSelect)(
  ({ theme }) => css`
    margin-bottom: ${pxToRem(20)};

    label {
      font-weight: ${theme.font.weight.medium};
    }
  `,
)

const BirthPickerInfo = styled.div(
  ({ theme }) => css`
    margin-bottom: ${pxToRem(40)};
    color: ${theme.color.grey['500']};
    font-size: ${pxToRem(16)};
    line-height: 1.5;
  `,
)

const SSelect = styled(Select)(
  ({ theme }) => css`
    margin-bottom: ${pxToRem(20)};

    .react-select__single-value {
      color: ${theme.color.dark};
    }
  `,
)

const SCHEMA = object().shape({
  fullName: FULL_NAME_SCHEMA,
  city: CITY_SCHEMA,
  country: COUNTRY_SCHEMA,
  timezone: TIMEZONE_SCHEMA,
  gender: GENDER_SCHEMA,
  dateOfBirth: DATE_OF_BIRTH_SCHEMA,
})

const INIT_VALUES = {
  fullName: '',
  city: '',
  country: '',
  timezone: getBrowserTimezone(),
  gender: '',
  dateOfBirth: '' as any as Date,
}

export type CreateAccountValues = typeof INIT_VALUES

type Props = {
  className?: string
  values?: CreateAccountValues
  onSubmit(values: CreateAccountValues): void
}

export const CreateAccountForm: React.FC<Props> = ({
  className,
  values = {},
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={{ ...INIT_VALUES, ...values }}
      validationSchema={SCHEMA}
      onSubmit={onSubmit}
    >
      <SForm data-testid="createAccountForm" className={className}>
        <STitle type="h1">Create account</STitle>
        <SFormikInput
          popError
          autoComplete="nope"
          data-testid="createAccountFormFullName"
          name="fullName"
          label="Full Name"
          placeholder="Full Name"
        />
        <SFormikInput
          popError
          autoComplete="nope"
          data-testid="createAccountFormCity"
          name="city"
          label="City"
          placeholder="City"
        />
        <SCountriesSelect
          isSearchable
          autoComplete="nope"
          data-testid="createAccountFormCountry"
          name="country"
          label="Country"
          popError={true}
          withError
          isClearable={false}
          placeholder="Country"
        />
        <STimezonesSelect
          isSearchable
          data-testid="createAccountFormTimezone"
          name="timezone"
          label="Timezone"
          popError={true}
          withError
          isClearable={false}
          placeholder="Timezone"
        />
        <SSelect
          data-testid="createAccountFormGender"
          name="gender"
          label="Gender"
          popError={true}
          withError
          isClearable={false}
          options={GENDER}
          placeholder="Gender"
        />
        <SBirthPicker name="dateOfBirth" popError={true} />
        <BirthPickerInfo>
          You need to be 18 years or older to create an account. User
          information will remain private and will not be shared. For more
          information, see our User Terms Policy.
        </BirthPickerInfo>
        <SButton
          buttonType="primary"
          data-testid="createAccountFormSubmit"
          type="submit"
        >
          Next
        </SButton>
      </SForm>
    </Formik>
  )
}
CreateAccountForm.displayName = 'CreateAccountForm'
