import styled from 'styled-components'

import { Button } from '../../Button/Button'
import { pxToRem } from '../../lib/utils'

export const EyeButton = styled(Button)`
  position: absolute;
  right: 0;
  && {
    padding: ${pxToRem(14, 16)};
  }
  :hover,
  :focus,
  :active {
    outline: none;
    border: ${pxToRem(1)} dotted transparent;
  }
  :disabled {
    background: transparent;
    :hover {
      background: transparent;
    }
  }
  svg {
    position: relative;
    margin: 0;
  }
`
