import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useField } from 'formik'

import { Props as SelectProps, Select } from '../Select'
import { pxToRem } from '../../../utils/utils'
import { useTimezones } from './options/useTimezones'

const SSelect = styled(Select)(
  ({ theme }) => css`
    margin-bottom: 0.25rem;

    label {
      font-weight: ${theme.font.weight.semibold};
    }

    .description {
      font-weight: ${theme.font.weight.regular};
      color: ${theme.color.medium};
      line-height: 1.3;
    }
  `,
)

const Warning = styled.div(
  ({ theme }) => css`
    font-weight: ${theme.font.weight.regular};
    color: ${theme.color.secondary[400]};
    line-height: 1.3;
  `,
)

const Wrap = styled.div(
  ({ theme }) => css`
    box-sizing: border-box;
    cursor: pointer;
    color: ${theme.color.dark};
    margin: ${pxToRem(12, 0)};
  `,
)

const FirstLine = styled.div(
  ({ theme }) => css`
    text-align: left;
    font-size: ${pxToRem(16)};
    color: ${theme.color.dark};
    font-weight: ${theme.font.weight.medium};
    box-sizing: border-box;
    cursor: pointer;
    padding-left: 1rem;
    line-height: 1;
  `,
)

const SecondLine = styled.div(
  ({ theme }) => css`
    text-align: left;
    font-size: ${pxToRem(14)};
    color: ${theme.color.medium};
    font-weight: ${theme.font.weight.regular};
    box-sizing: border-box;
    cursor: pointer;
    padding-left: 1rem;
    line-height: 1;
  `,
)

const CustomOption = ({ innerProps, label }: any): any => {
  const [firstLine, secondLine] = label.split(';')
  return (
    <Wrap {...innerProps}>
      <FirstLine>{firstLine}</FirstLine>
      <SecondLine>{secondLine}</SecondLine>
    </Wrap>
  )
}

const CustomValue = ({ innerProps, data }: any): any => {
  const [firstLine] = (data?.label || '').split(';')
  return <Wrap {...innerProps}>{firstLine}</Wrap>
}

type Props = Omit<SelectProps, 'options'>

export const TimezonesSelect: React.FC<Props> = (props) => {
  const { data, loading, error } = useTimezones()
  const [{ value }, {}, { setValue }] = useField(props.name)

  useEffect(() => {
    if (loading || !value) {
      return
    }
    const isTimezonePresent = data.some(
      ({ value: dataValue }) => value === dataValue,
    )
    if (!isTimezonePresent) {
      setValue('')
    }
  }, [loading])

  return (
    <>
      <SSelect
        options={data || []}
        {...props}
        isLoading={loading}
        popError
        placeholder={props.placeholder || ''}
        description={
          error ? (
            <>
              {props.description}
              <Warning>
                There was an error loading the timezones.
                <br /> Please try again later.
              </Warning>
            </>
          ) : (
            props.description
          )
        }
        OptionComponent={CustomOption}
        ValueComponent={CustomValue}
      />
    </>
  )
}
TimezonesSelect.displayName = 'TimezonesSelect'
