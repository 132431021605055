import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { Wrap } from './CircleLoader.style'
import { DefaultTheme } from '../../themes'

type Props = {
  centered?: boolean
  className?: string
}

export const CircleLoader: React.FC<Props> = ({ centered, className }) => {
  const centeredClass = centered ? 'centered ' : ''
  const theme = useContext(ThemeContext) || DefaultTheme
  return (
    <Wrap
      theme={theme}
      className={`${centeredClass}${className}`}
      data-testid="circle-loader"
    >
      <div />
      <div />
      <div />
      <div />
    </Wrap>
  )
}

CircleLoader.displayName = 'CircleLoader'
