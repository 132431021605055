import React, { InputHTMLAttributes, useContext, useCallback } from 'react'
import { ThemeContext } from 'styled-components'

import { Input, Props as InputProps } from '../Input/Input'
import { EyeButton } from './PasswordInput.style'
import { Eye } from '../../icons/Eye'
import { DefaultTheme } from '../../themes'

export type Props = InputHTMLAttributes<HTMLInputElement> &
  InputProps & {
    label: string
    name: string
    className?: string
    setValue(value: string | number): void
    value: string
    id?: string
    error?: string | null
  }

export const PasswordInput: React.FC<Props> = ({
  name,
  id = name,
  label = '',
  className,
  setValue,
  value,
  info,
  disabled,
  ...rest
}) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  const [showPass, setShowPass] = React.useState(false)
  const setPass = useCallback((ev: React.MouseEvent) => {
    ev.preventDefault()
    setShowPass((prev) => !prev)
  }, [])

  return (
    <>
      <Input
        id={id}
        value={value}
        setValue={setValue}
        label={label}
        disabled={disabled}
        className={className}
        info={info}
        type={showPass ? 'text' : 'password'}
        {...rest}
      >
        <EyeButton
          theme={theme}
          disabled={disabled}
          data-testid="password-input-eye"
          type="button"
          onClick={setPass}
        >
          <Eye open={!showPass} />
        </EyeButton>
      </Input>
    </>
  )
}
PasswordInput.displayName = 'PasswordInput'
