import React from 'react'
import styled, { css } from 'styled-components'
import { useConsent } from '@style-space/components/src/lib/consent'
import { CookieModal } from '@style-space/components/src/CookiePolicy/CookieModal'
import { useUser } from '@style-space/components/src/Context/AuthContext/hooks/useUser'
import { useLogin } from '@style-space/components/src/Context/AuthContext/hooks/useLogin'
import { ConfirmationModal } from '@style-space/components/src/Modal/ConfirmationModal'
import { FormikSubmit } from '@style-space/components/src/Form/types'
import { useRouter } from 'next/router'
import { CircleLoader } from '@style-space/components/src/Loader/CircleLoader'
import { Button } from '@style-space/components/src/Button'

import { LoginValues, SignInForm } from './SignInForm'
import { pxToRem } from '../../utils/utils'
import { Modal } from '../modals/Modal'
import { getWelcomeRoute } from '../../utils/routes'

const Text = styled.div(
  ({ theme }) => css`
    color: ${theme.color.grey['900']};
    font-size: ${pxToRem(16)};
    line-height: 1.5;
    width: 100%;
    text-align: center;

    :first-of-type {
      margin-top: ${pxToRem(40)};
    }
  `,
)

const SModal = styled(Modal)(
  ({ theme }) => css`
    .headless-modal-inner-wrap {
      border-radius: ${theme.radius.small};
      max-width: ${pxToRem(600)};
      padding: ${pxToRem(64)};
    }

    .headless-modal-title {
      margin-bottom: ${pxToRem(40)};
      line-height: 1.125;
    }
    .headless-modal-x {
      top: ${pxToRem(30)};
      right: ${pxToRem(30)};
      padding: ${pxToRem(8)};
      svg {
        width: ${pxToRem(14)};
        height: ${pxToRem(14)};
      }
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      max-width: 100%;

      .headless-modal-inner-wrap {
        padding: ${pxToRem(64, 45)};
      }
    }
  `,
)

const SButton = styled(Button)`
  && {
    margin-left: ${pxToRem(3)};
  }
`

type Props = {
  isOpen: boolean
  onClose(withCallback?: boolean): void
  signUp?: boolean
  onSignUp(): void
  onForgot(): void
  className?: string
}

export const SignInModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSignUp,
  onForgot,
  className,
}) => {
  const router = useRouter()
  const [internalConsent, setInternalConsent] = React.useState(false)
  const { consent, updateCookieConsent } = useConsent()
  const { data: user, loading: userLoading } = useUser()
  const { data, login, error, loading, isUnverified, isFirstLogin } = useLogin()
  const isSuccess = Boolean(data)
  const [values, setValues] = React.useState<LoginValues>()
  const onSubmit = React.useCallback<FormikSubmit<LoginValues>>(
    async (values, actions) => {
      const { email, password } = values
      setValues(values)
      const success = await login(email, password)
      if (success) {
        actions.resetForm()
        onClose(false)
      }
    },
    [login, setValues],
  )

  React.useEffect(() => {
    if (isFirstLogin) {
      router.push(getWelcomeRoute())
    }
  }, [data])

  React.useEffect(() => {
    if (!consent) {
      setTimeout(() => {
        setInternalConsent(true)
      }, 300)
    }
  }, [consent])

  if (userLoading || user) {
    return null
  }

  if (error && isUnverified) {
    return (
      <ConfirmationModal
        modalName="Sign In"
        onClose={onClose}
        icon="x"
        title="Confirm your identity"
        text="The email associated with this account is not confirmed. Confirm the email address and try again."
        isOpen={isOpen}
        className={className}
      />
    )
  }

  if (loading || isSuccess) {
    return (
      <SModal
        modalName="Sign in"
        title=""
        onClose={() => {}}
        isOpen={isOpen}
        className={className}
      >
        <CircleLoader centered />
      </SModal>
    )
  }

  return (
    <SModal
      modalName="Sign in"
      title="Sign in"
      isOpen={isOpen}
      onClose={onClose}
      className={className}
    >
      <SignInForm
        onForgot={onForgot}
        error={error}
        values={values}
        onSubmit={onSubmit}
      />
      <Text>
        Don't have an account?
        <SButton
          buttonType="inline-link"
          uppercase={false}
          data-testid="signInModalSingUp"
          onClick={onSignUp}
        >
          Sign Up
        </SButton>
      </Text>
      {internalConsent && (
        <CookieModal
          isOpen={isOpen}
          onClose={() => {
            setInternalConsent(false)
            onClose()
          }}
          onSubmit={(values) => {
            setInternalConsent(false)
            updateCookieConsent(values)
          }}
        />
      )}
    </SModal>
  )
}
SignInModal.displayName = 'SignInModal'
