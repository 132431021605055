import React from 'react'
import { useField } from 'formik'

import { PasswordInput, Props as PasswordInputProps } from '../PasswordInput'

type Props = Omit<PasswordInputProps, 'value' | 'setValue' | 'error'> & {
  label: string
  name: string
  className?: string
}

export const FormikPasswordInput: React.FC<Props> = ({
  name,
  label,
  className,
  info,
  ...rest
}) => {
  const [field, { error, touched }, { setValue }] = useField(name)
  const isError = Boolean(error && touched)
  return (
    <PasswordInput
      setValue={setValue}
      label={label}
      className={className}
      error={isError ? error : undefined}
      info={info}
      {...field}
      {...rest}
    ></PasswordInput>
  )
}
FormikPasswordInput.displayName = 'FormikPasswordInput'
