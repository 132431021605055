import React from 'react'
import styled, { css } from 'styled-components'
import { SureModal } from '@style-space/components/src/Modal/SureModal'

import { Modal } from '../modals/Modal'
import { SignUp } from './SignUp'
import { pxToRem } from '../../utils/utils'

const SModal = styled(Modal)(
  ({ theme }) => css`
    &.hidden {
      .headless-modal-wrap {
        left: -9999px;
      }
      .headless-modal-overlay {
        left: -9999px;
      }
    }
    .headless-modal-inner-wrap {
      border-radius: ${theme.radius.small};
      padding: ${pxToRem(64)};
      max-width: ${pxToRem(600)};

      @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
        max-width: 100%;
        padding: ${pxToRem(64, 45)};
      }
    }
    .headless-modal-x {
      top: ${pxToRem(30)};
      right: ${pxToRem(30)};
      padding: ${pxToRem(8)};
      svg {
        width: ${pxToRem(14)};
        height: ${pxToRem(14)};
      }
    }
  `,
)

type Props = {
  isOpen: boolean
  onClose(withCallback?: boolean): void
  onSignIn(): void
  className?: string
}

export const SignUpModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSignIn,
  className,
}) => {
  const [sure, setSure] = React.useState(false)
  const [done, setDone] = React.useState(false)

  return (
    <SModal
      modalName="Sign up"
      holdOpen
      isOpen={isOpen}
      onClose={() => {
        if (done) {
          onSignIn()
          return
        }
        setSure(true)
      }}
      className={className}
    >
      <SignUp
        onSignIn={onSignIn}
        onFinal={() => {
          setDone(true)
        }}
      />
      {sure && (
        <SureModal
          isOpen={true}
          title={'Are you sure?'}
          text={'Whole registration progress will be lost'}
          onConfirm={onClose}
          onBack={() => setSure(false)}
        />
      )}
    </SModal>
  )
}
SignUpModal.displayName = 'SignUpModal'
