import styled, { css } from 'styled-components'

import { pxToRem } from '../../lib/utils'
import { Modal } from '../Modal'

export const SModal = styled(Modal)(
  ({ theme }) => css`
    .headless-modal-inner-wrap {
      border-radius: ${theme.radius.small};
      max-width: ${pxToRem(600)};
      padding: ${pxToRem(64)};

      .headless-modal-title {
        margin-bottom: ${pxToRem(40)};
        line-height: 1.125;
        text-align: center;
      }
      .headless-modal-x {
        top: ${pxToRem(30)};
        right: ${pxToRem(30)};
        padding: ${pxToRem(8)};
        svg {
          width: ${pxToRem(14)};
          height: ${pxToRem(14)};
        }
      }
    }
  `,
)
