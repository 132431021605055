import React, { useContext } from 'react'

import { AuthContext } from '../../AuthContext'
import { getUpdateUserAction } from '../../actions'
import { useMutation } from '../../../../lib/graphql/useMutation'
import { setToken } from '../../../../lib/graphql/token'
import { ArgumentError } from '../../../../lib/error'
import { identify } from '../../../../lib/mixpanel/utils'
import { QUERY } from './query'
import { transformData } from '../useUser/utils'
import { LoginUserData } from './types'
import { User } from '../../../../Context/AuthContext/types'
import { selectUser } from '../../selectors'

export const UNVERIFIED_ERROR = 'Unverified Email'

const EXPECTED_ERRORS: { [key: string]: string } = {
  'Authentication Failed': 'Entered e-mail or password are incorrect.',
  [UNVERIFIED_ERROR]: 'Error: Unverified email address',
}

type UseLoginResponse = {
  login: (email: string, password: string) => Promise<boolean>
  data: User | null
  loading: boolean
  isFirstLogin: boolean
  isUnverified: boolean
  error: string | null
}

export const useLogin = (): UseLoginResponse => {
  const { state, dispatch } = useContext(AuthContext)
  const {
    mutate: loginMutate,
    loading,
    error,
    data,
  } = useMutation<LoginUserData>(QUERY, {
    headers: { Authorization: '' },
  })
  const login = React.useCallback(
    async (email: string, password: string) => {
      const data = await loginMutate({
        variables: {
          email,
          password,
        },
      })
      if (!data || !data?.login) {
        return false
      }
      const {
        login: {
          user: { id },
          token,
          expiry,
        },
      } = data
      identify(id)
      setToken({ token, expiry })
      dispatch(getUpdateUserAction(transformData(data.login.user)))
      return true
    },
    [loginMutate],
  )

  if (error && !EXPECTED_ERRORS[error]) {
    throw new ArgumentError('useLogin', error)
  }

  return {
    login,
    data: selectUser(state),
    loading,
    isFirstLogin: Boolean(data?.login?.user?.userProfile?.isFirstLogin),
    isUnverified: Boolean(error && error === UNVERIFIED_ERROR),
    error: error ? EXPECTED_ERRORS[error] : null,
  }
}
