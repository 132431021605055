import React from 'react'
import { CircleLoader } from '@style-space/components/src/Loader/CircleLoader'
import { ConfirmationModalInner } from '@style-space/components/src/Modal/ConfirmationModal/ConfirmationModalInner'

import { SignUpForm, SignUpValues } from './SignUpForm'
import { CreateAccountForm, CreateAccountValues } from './CreateAccountForm'
import { useSignUp } from '../../data/hooks/User/useSignUp'

type Props = {
  onFinal(): void
  onSignIn(): void
}

export const SignUp: React.FC<Props> = ({ onSignIn, onFinal }) => {
  const [signUpValues, setSignUpValues] = React.useState<SignUpValues>()
  const [createAccountValues, setCreateAccountValues] =
    React.useState<CreateAccountValues>()

  const {
    signup,
    loading: signUpLoading,
    error: signUpError,
  } = useSignUp(onFinal)

  const onSignUpFormSubmit = React.useCallback(
    (values: SignUpValues) => {
      setSignUpValues(values)
    },
    [setSignUpValues],
  )

  const onCreateAccountFormSubmit = React.useCallback(
    async (values: CreateAccountValues) => {
      if (!signUpValues) {
        throw new Error('Signup values should be defined at this point')
      }
      setCreateAccountValues(values)
      await signup({ ...signUpValues, ...values })
    },
    [setCreateAccountValues, signUpLoading, signup, signUpValues],
  )

  if (!signUpValues) {
    return <SignUpForm onSignIn={onSignIn} onSubmit={onSignUpFormSubmit} />
  }

  if (!createAccountValues) {
    return <CreateAccountForm onSubmit={onCreateAccountFormSubmit} />
  }

  if (signUpLoading) {
    return <CircleLoader centered />
  }

  if (signUpError) {
    return (
      <ConfirmationModalInner
        title="Sign up unsuccessful"
        text={signUpError}
        icon="x"
      />
    )
  }

  return (
    <ConfirmationModalInner
      title="Confirm your identity"
      text="We have sent you an email to confirm you email adress. Please click the link to confirm you address."
      icon="shield"
    />
  )
}
SignUp.displayName = 'SignUp'
