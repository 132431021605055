import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { SModal } from './ConfirmationModal.style'
import { DefaultTheme } from '../../themes'
import {
  ConfirmationModalInner,
  Props as InnerProps,
} from './ConfirmationModalInner'

type Props = InnerProps & {
  modalName: string
  className?: string
  isOpen?: boolean
  onClose(): void
}

export const ConfirmationModal: React.FC<Props> = ({
  className = '',
  isOpen,
  onClose,
  modalName,
  children,
  ...rest
}) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  return (
    <SModal
      data-testid="confirmationModal"
      isOpen={isOpen}
      theme={theme}
      onClose={onClose}
      modalName={modalName}
      className={className}
    >
      <ConfirmationModalInner {...rest}>{children}</ConfirmationModalInner>
    </SModal>
  )
}
ConfirmationModal.displayName = 'ConfirmationModal'
