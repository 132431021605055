import React from 'react'
import { Form, Formik } from 'formik'
import { object, string } from '@style-space/components/src/Form/schema'
import styled, { css } from 'styled-components'
import { FormikSubmit } from '@style-space/components/src/Form/types'
import { FormikInput } from '@style-space/components/src/Form/Input/FormikInput'
import { FormikPasswordInput } from '@style-space/components/src/Form/PasswordInput/FormikPasswordInput'
import { Button } from '@style-space/components/src/Button'

import { ExclamationCircle } from '../common/icons/ExclamationCircle'
import { pxToRem } from '../../utils/utils'
import { EMAIL_SCHEMA } from '../../utils/schemas'

const SForm = styled(Form)`
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
`

const SFormikInput = styled(FormikInput)`
  margin-bottom: ${pxToRem(20)};
`

const SFormikPasswordInput = styled(FormikPasswordInput)`
  margin-bottom: ${pxToRem(20)};
`

const InnerWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const SButton = styled(Button)`
  width: 100%;
  margin-top: 1.5rem;
`

const SButtonLink = styled(Button)(
  ({ theme }) => css`
    && {
      position: relative;
      font-size: ${pxToRem(16)};
      line-height: 1.5;
      padding: 0;
      border: none;
      color: ${theme.color.primary['500']};
      font-weight: ${theme.font.weight.regular};
      text-decoration: underline;
    }
  `,
)

const ErrorWrap = styled.div(
  ({ theme }) => css`
    margin-bottom: ${pxToRem(20)};
    font-size: ${pxToRem(16)};
    line-height: 1.5;
    height: ${pxToRem(56)};
    color: ${theme.color.red['800']};
    background-color: ${theme.color.red['50']};
    padding: ${pxToRem(16)};
    font-weight: ${theme.font.weight.bold};
    text-align: left;
    display: flex;
    align-items: center;
  `,
)

const SExclamationCircle = styled(ExclamationCircle)`
  margin-right: ${pxToRem(8)};
  margin-top: ${pxToRem(-2)};
`

const SCHEMA = object().shape({
  email: EMAIL_SCHEMA,
  password: string().required('Password is required'),
})

const INIT_VALUES = {
  email: '',
  password: '',
}

export type LoginValues = typeof INIT_VALUES

type Props = {
  className?: string
  error?: string | null
  values?: Partial<LoginValues>
  onForgot(): void
  onSubmit: FormikSubmit<LoginValues>
}

export const SignInForm: React.FC<Props> = ({
  className,
  values = {},
  onSubmit,
  onForgot,
  error,
}) => {
  return (
    <Formik
      initialValues={{ ...INIT_VALUES, ...values }}
      validationSchema={SCHEMA}
      validateOnBlur
      onSubmit={onSubmit}
    >
      <SForm data-testid="signInForm" className={className}>
        <SFormikInput
          popError
          label="Email"
          data-testid="signInEmail"
          name="email"
          placeholder="Enter your e-mail"
        />
        <SFormikPasswordInput
          popError
          label="Password"
          data-testid="signInPassword"
          name="password"
          placeholder="Enter your password"
        />
        {error && (
          <ErrorWrap>
            <SExclamationCircle />
            {error}
          </ErrorWrap>
        )}
        <InnerWrap>
          <SButtonLink
            buttonType="link"
            buttonSize="small"
            uppercase={false}
            type="button"
            onClick={onForgot}
            data-testid="signInForgot"
          >
            Forgot password?
          </SButtonLink>
        </InnerWrap>
        <SButton
          buttonType="primary"
          buttonSize="regular"
          data-testid="signInSubmit"
          type="submit"
        >
          SIGN IN
        </SButton>
      </SForm>
    </Formik>
  )
}
SignInForm.displayName = 'SignInForm'
