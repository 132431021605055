import styled, { css } from 'styled-components'

import { Button } from '../../Button'
import { pxToRem } from '../../lib/utils'
import { Modal } from '../Modal'

export const SModal = styled(Modal)(
  ({ theme }) => css`
    .headless-modal-inner-wrap {
      padding: ${pxToRem(63, 67)};
      max-width: ${pxToRem(465)};

      @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
        min-height: 0;
        padding: ${pxToRem(63, 20)};
        margin: auto auto;
      }

      .headless-modal-title {
        width: 100%;
        text-align: center;
      }
    }
  `,
)

export const Text = styled.p(
  ({ theme }) => css`
    width: 100%;
    color: ${theme.color.medium};
    padding: ${pxToRem(0, 20, 27)};
    text-align: center;
    line-height: 1.5;
    font-size: ${pxToRem(14)};
  `,
)

export const SLightButton = styled(Button)(
  ({ theme }) => css`
    line-height: 1;
    display: block;
    width: 100%;
    text-transform: uppercase;
    margin-right: ${pxToRem(32)};
    max-width: ${pxToRem(330)};
    font-weight: ${theme.font.weight.bold};
  `,
)

export const SBlueButton = styled(Button)(
  ({ theme }) => css`
    line-height: 1;
    display: block;
    width: 100%;
    text-transform: uppercase;
    max-width: ${pxToRem(330)};
    font-weight: ${theme.font.weight.bold};
  `,
)

export const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  margin: ${pxToRem(40)} auto 0;
`
