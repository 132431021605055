import React from 'react'

import { SignInModal } from './SignInModal'
import { SignUpModal } from './SignUpModal'
import { ForgotModal } from './ForgotModal/ForgotModal'

export type SignModal = 'signIn' | 'signUp' | 'forgot' | null

type Props = {
  modal: SignModal
  setModal(modal: SignModal): void
}

export const SignModal: React.FC<Props> = ({ modal, setModal }) => {
  const onSignUp = React.useCallback(() => {
    setModal('signUp')
  }, [setModal])

  const onSignIn = React.useCallback(() => {
    setModal('signIn')
  }, [setModal])

  const onForgot = React.useCallback(() => {
    setModal('forgot')
  }, [setModal])

  const onClose = React.useCallback(() => {
    setModal(null)
  }, [setModal])

  return (
    <>
      {modal === 'signIn' && (
        <SignInModal
          isOpen
          onClose={onClose}
          onSignUp={onSignUp}
          onForgot={onForgot}
        />
      )}
      {modal === 'signUp' && (
        <SignUpModal isOpen onClose={onClose} onSignIn={onSignIn} />
      )}

      {modal === 'forgot' && <ForgotModal isOpen onClose={onClose} />}
    </>
  )
}
SignModal.displayName = 'SignModal'
