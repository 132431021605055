import { useQuery } from '@style-space/components/src/lib/graphql/useQuery'
import { captureError } from '@style-space/components/src/lib/monitoring/sentry'
import { useMemo } from 'react'

import { Options } from '../../../../../@types/types'
import { formatDate, getDate, isSupportedTimezone } from '../../../../lib/time'

export const QUERY = `
  query Timezones {
    timezones(pagination: { offset: 0, count: -1 }) {
      results {
        code
        zone
        niceName
        name
        utcOffset
      }
    }
  }
`

const TRANSFORM_MAP: { [key: string]: string } = {
  'US/Pacific': '(PST/PDT) US/Pacific Time',
  'US/Mountain': '(MST/MDT) US/Mountain Time',
  'US/Eastern': '(EST/EDT) US/Eastern Time',
  'US/Central': '(CST/CDT) US/Central Time',
}

const ignoreTimezone = ({ niceName }: RawTimezone) => {
  return niceName.startsWith('Unknown Region')
}

type RawTimezone = {
  code: string
  utcOffset: number
  niceName: string
  name: string
  zone: string
}

export const useTimezones = () => {
  const { loading, data, error } = useQuery(QUERY)

  if (error) {
    captureError(new Error('Timezones fetch failed'))
  }

  const results = (data?.timezones.results || []) as RawTimezone[]
  const options = useMemo(
    () =>
      [...results]
        .sort(
          (
            { utcOffset: utcOffsetA, name },
            { utcOffset: utcOffsetB, name: nameB },
          ) => {
            const transA = Number(Boolean(TRANSFORM_MAP[name]))
            const transB = Number(Boolean(TRANSFORM_MAP[nameB]))
            if (transA || transB) {
              return transB - transA
            }
            return utcOffsetA - utcOffsetB
          },
        )
        .reduce((res, value) => {
          const { utcOffset, zone, code, name } = value
          if (ignoreTimezone(value) || !isSupportedTimezone(name)) {
            return res
          }
          const GMTString = `(GMT${
            utcOffset >= 0 ? `+${utcOffset}` : `${utcOffset}`
          })`
          const zoneString =
            zone.startsWith('+') || zone.startsWith('-') ? '' : ` (${zone})`

          const currentTime = formatDate(getDate(), `h.mma dddd`, name)
          return [
            ...res,
            {
              value: code,
              label: `${
                TRANSFORM_MAP?.[name]
                  ? TRANSFORM_MAP[name]
                  : `${zoneString} ${name}`
              }; ${GMTString} ${currentTime}`,
            },
          ]
        }, [] as Options),
    [results],
  )

  return {
    data: options,
    error: error && 'Timezones fetch failed',
    loading,
  }
}
