import React from 'react'
import { Form, Formik } from 'formik'
import {
  boolean,
  object,
  ref,
  string,
} from '@style-space/components/src/Form/schema'
import styled, { css } from 'styled-components'
import { LEGAL_DOCUMENTS_ROUTES } from '@style-space/components/src/lib/utils'
import { FormikInput } from '@style-space/components/src/Form/Input/FormikInput'
import { FormikPasswordInput } from '@style-space/components/src/Form/PasswordInput/FormikPasswordInput'
import { FormikCheckbox } from '@style-space/components/src/Form/Checkbox/FormikCheckbox'
import { Button } from '@style-space/components/src/Button'
import { Anchor } from '@style-space/components/src/Anchor'

import { pxToRem } from '../../utils/utils'
import { Title } from '../common/Title'
import { EMAIL_SCHEMA, PASSWORD_SCHEMA } from '../../utils/schemas'

const SForm = styled(Form)(
  ({ theme }) => css`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      max-width: none;
    }
  `,
)

const SFormikCheckbox = styled(FormikCheckbox)`
  margin-bottom: ${pxToRem(40)};
  label {
    span {
      font-size: ${pxToRem(16)};
      line-height: 1.5;
    }
  }
`

const SButton = styled(Button)`
  width: 100%;
  margin-bottom: ${pxToRem(40)};
`

const ErrorWrap = styled.div(
  ({ theme }) => css`
    padding: 0.5rem;
    background-color: ${theme.color.lighter};
    text-align: left;
    color: ${theme.color.red['500']};
    font-size: ${pxToRem(14)};
    line-height: 1.5;
    margin-bottom: 1.25rem;
  `,
)

const Text = styled.div(
  ({ theme }) => css`
    color: ${theme.color.medium};
    font-size: ${pxToRem(14)};
    width: 100%;
    text-align: center;

    :first-of-type {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  `,
)

const STitle = styled(Title)(
  ({ theme }) => css`
    font-weight: ${theme.font.weight.regular};
    margin-bottom: ${pxToRem(40)};
    font-size: ${pxToRem(32)};
    line-height: 1.125;
  `,
)

const SBlueButtonLink = styled(Button)`
  && {
    margin-left: ${pxToRem(3)};
    text-decoration: underline;
    font-size: ${pxToRem(14)};
  }
`

const SFormikInput = styled(FormikInput)`
  margin-bottom: ${pxToRem(20)};
`

const SFormikPasswordInput = styled(FormikPasswordInput)`
  &.signUpPassword {
    margin-bottom: ${pxToRem(20)};
  }
  &.signUpPasswordConfirm {
    margin-bottom: ${pxToRem(40)};
  }
`

const SCHEMA = object().shape({
  email: EMAIL_SCHEMA,
  confirmEmail: string()
    .test('confirm-email', 'Emails must match', (value, data: any) => {
      const email = (data.parent?.email || '').toLowerCase()
      const confirmEmail = (value || '').toLowerCase()
      return email === confirmEmail
    })
    .required('Email confirmation required'),
  password: PASSWORD_SCHEMA,
  confirmPassword: string()
    .oneOf([ref('password'), null as any], 'Passwords must match')
    .required('Password confirmation required'),
  policy: boolean().oneOf([true], 'You have to agree to continue'),
})

const INIT_VALUES = {
  email: '',
  confirmEmail: '',
  password: '',
  confirmPassword: '',
  policy: false,
}

export type SignUpValues = typeof INIT_VALUES

type Props = {
  className?: string
  error?: string
  values?: Partial<SignUpValues>
  onSignIn(): void
  onSubmit(values: SignUpValues): void
}

export const SignUpForm: React.FC<Props> = ({
  className,
  error,
  values = {},
  onSignIn,
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={{ ...INIT_VALUES, ...values }}
      validationSchema={SCHEMA}
      onSubmit={onSubmit}
    >
      <SForm data-testid="signUpForm" className={className}>
        <STitle type="h1">Sign up</STitle>
        <SFormikInput
          popError
          data-testid="signUpEmail"
          name="email"
          label="Email"
          placeholder="Enter your e-mail"
        />
        <SFormikInput
          popError
          data-testid="signUpEmailConfirm"
          name="confirmEmail"
          label="Confirm Email"
          placeholder="Repeat your e-mail"
        />
        <SFormikPasswordInput
          popError
          className="signUpPassword"
          data-testid="signUpPassword"
          name="password"
          label="Password"
          placeholder="Enter your password"
          info="The password should be at least 8 characters long and contain at least
          one digit."
        />
        <SFormikPasswordInput
          popError
          className="signUpPasswordConfirm"
          data-testid="signUpPasswordConfirm"
          name="confirmPassword"
          label="Confirm Password"
          placeholder="Repeat your password"
        />
        <SFormikCheckbox popError data-testid="signUpPolicy" name="policy">
          Yes, I agree to the{' '}
          <Anchor
            anchorType="inline"
            underline
            target="_blank"
            href={LEGAL_DOCUMENTS_ROUTES.termsOfService}
          >
            Terms and Conditions
          </Anchor>{' '}
          and Style Space’s{' '}
          <Anchor
            anchorType="inline"
            underline
            target="_blank"
            href={LEGAL_DOCUMENTS_ROUTES.privacyPolicy}
          >
            Privacy Policy
          </Anchor>{' '}
          as well as the Credit Check.
        </SFormikCheckbox>
        {error && <ErrorWrap>{error}</ErrorWrap>}
        <SButton
          buttonType="primary"
          buttonSize="regular"
          data-testid="signUpSubmit"
          type="submit"
        >
          NEXT
        </SButton>
        <Text>
          Already have an account?
          <SBlueButtonLink
            buttonType="inline-link"
            uppercase={false}
            data-testid="signUpSignIn"
            onClick={onSignIn}
          >
            Sign In
          </SBlueButtonLink>
        </Text>
      </SForm>
    </Formik>
  )
}
SignUpForm.displayName = 'SignUpForm'
