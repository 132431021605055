import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { DefaultTheme } from '../../themes'
import {
  ButtonWrap,
  SBlueButton,
  SLightButton,
  SModal,
  Text,
} from './SureModal.style'

type Props = {
  isOpen: boolean
  title: string
  text: string | JSX.Element
  confirmButtonText?: string
  backButtonText?: string
  onBack(): void
  onConfirm(): void
  className?: string
}

export const SureModal: React.FC<Props> = ({
  isOpen,
  title,
  text,
  onBack,
  backButtonText = 'go back',
  confirmButtonText = 'continue',
  onConfirm,
  className,
}) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  return (
    <SModal
      theme={theme}
      modalName="Sure modal"
      withX={false}
      title={title}
      isOpen={isOpen}
      onClose={onBack}
      className={className}
    >
      <Text theme={theme}>{text}</Text>
      <ButtonWrap theme={theme}>
        <SLightButton
          theme={theme}
          buttonType="secondary"
          onClick={onBack}
          data-testid="confirmModalConfirm"
          type="button"
        >
          {backButtonText}
        </SLightButton>
        <SBlueButton
          theme={theme}
          buttonType="primary"
          onClick={onConfirm}
          data-testid="confirmModalConfirm"
          type="button"
        >
          {confirmButtonText}
        </SBlueButton>
      </ButtonWrap>
    </SModal>
  )
}
SureModal.displayName = 'SureModal'
